// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

var arrow = '\u279e';

var middot = '\u00b7';

var mdash = '\u2014';

var rightCaret = '\u203a';

var ampersand = '\u0026';

var times = '\u00D7';

function Char$Middot(props) {
  return JsxRuntime.jsx("span", {
              children: " " + (middot + " "),
              style: {
                padding: "0 1px"
              }
            });
}

var Middot = {
  make: Char$Middot
};

function Char$RightArrow(props) {
  return JsxRuntime.jsx("div", {
              children: arrow,
              className: props.className
            });
}

var RightArrow = {
  make: Char$RightArrow
};

function Char$LeftArrow(props) {
  return JsxRuntime.jsx("div", {
              children: arrow,
              style: {
                transform: "rotate(180deg)"
              }
            });
}

var LeftArrow = {
  make: Char$LeftArrow
};

function Char$RightCaret(props) {
  return JsxRuntime.jsx("span", {
              children: rightCaret
            });
}

var RightCaret = {
  make: Char$RightCaret
};

function Char$Ampersand(props) {
  return JsxRuntime.jsx("span", {
              children: ampersand
            });
}

var Ampersand = {
  make: Char$Ampersand
};

function Char$Times(props) {
  return JsxRuntime.jsx("span", {
              children: times
            });
}

var Times = {
  make: Char$Times
};

export {
  arrow ,
  middot ,
  mdash ,
  rightCaret ,
  ampersand ,
  times ,
  Middot ,
  RightArrow ,
  LeftArrow ,
  RightCaret ,
  Ampersand ,
  Times ,
}
/* react/jsx-runtime Not a pure module */
