// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconSorting(props) {
  var direction = props.direction;
  var __title = props.title;
  var title = __title !== undefined ? __title : "Sort";
  return JsxRuntime.jsxs(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: [
                JsxRuntime.jsx("path", {
                      d: "M3 10L8 16L13 10H3Z",
                      fill: direction === "Desc" ? Icon.mapColor("Gray") : Icon.mapColor("LighterGray")
                    }),
                JsxRuntime.jsx("path", {
                      d: "M3 7L8 0L13 7H3Z",
                      fill: direction !== undefined && direction !== "Desc" ? Icon.mapColor("Gray") : Icon.mapColor("LighterGray")
                    })
              ]
            });
}

var make = IconSorting;

export {
  make ,
}
/* Icon Not a pure module */
