// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function TableBody(props) {
  return JsxRuntime.jsx("tbody", {
              children: props.children
            });
}

var make = TableBody;

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
