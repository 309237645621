// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as TableScss from "./Table.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = TableScss;

function textAlignToClass(textAlign) {
  switch (textAlign) {
    case "Left" :
        return css.alignLeft;
    case "Center" :
        return css.alignCenter;
    case "Right" :
        return css.alignRight;
    
  }
}

function TableHeaderCell(props) {
  var __displayOnMobile = props.displayOnMobile;
  var __textAlign = props.textAlign;
  var textAlign = __textAlign !== undefined ? __textAlign : "Left";
  var displayOnMobile = __displayOnMobile !== undefined ? __displayOnMobile : true;
  return JsxRuntime.jsx("th", {
              children: props.children,
              className: Cx.cx([
                    textAlignToClass(textAlign),
                    displayOnMobile ? "" : css.hideOnMobile
                  ])
            });
}

var make = TableHeaderCell;

export {
  css ,
  textAlignToClass ,
  make ,
}
/* css Not a pure module */
