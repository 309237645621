// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as RestBrowser from "../../RestBrowser.res.js";
import * as ActiveModelError from "../../../models/ActiveModelError.res.js";

function exec(name, active, description, contractStart, contract, homeUrl, adminUrl, username, encryptedPassword) {
  var formData = new FormData();
  formData.append("name", name);
  formData.append("active", active);
  formData.append("description", description);
  formData.append("contract_start", contractStart);
  formData.append("home_url", homeUrl);
  formData.append("admin_url", adminUrl);
  formData.append("username", username);
  formData.append("encrypted_password", encryptedPassword);
  if (contract !== undefined) {
    formData.append("contract_resource", contract);
  } else {
    formData.append("no_contract", undefined);
  }
  return RestBrowser.$$fetch("/dashboard/tsds", formData, {
              NAME: "Json",
              VAL: ID.Id.decoder
            }, {
              NAME: "Message",
              VAL: ActiveModelError.Errors.decoder
            }, "Post", undefined);
}

var $$FormData$1;

export {
  $$FormData$1 as $$FormData,
  exec ,
}
/* ID Not a pure module */
