// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as Pdf from "./Pdf.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          id: js.id,
          name: js.name,
          active: js.active,
          description: js.description,
          contract: js.contract,
          contractStart: js.contractStart,
          contractResource: Pdf.Resource.fromJs(js.contractResource),
          contractDownload: js.contractDownload,
          contractAttached: js.contractAttached,
          agenciesCount: js.agenciesCount,
          homeUrl: js.homeUrl,
          adminUrl: js.adminUrl,
          username: js.username,
          encryptedPassword: js.encryptedPassword,
          createdAt: js.createdAt,
          updatedAt: js.updatedAt
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              contract: field.required("contract", Json_Decode$JsonCombinators.string),
              contractStart: field.required("contractStart", Json_Decode$JsonCombinators.string),
              contractResource: field.required("contractResource", Pdf.Resource.decoder),
              contractDownload: field.required("contractDownload", Json_Decode$JsonCombinators.string),
              contractAttached: field.required("contractAttached", Json_Decode$JsonCombinators.bool),
              agenciesCount: field.required("agenciesCount", Json_Decode$JsonCombinators.$$int),
              homeUrl: field.required("homeUrl", Json_Decode$JsonCombinators.string),
              adminUrl: field.required("adminUrl", Json_Decode$JsonCombinators.string),
              username: field.required("username", Json_Decode$JsonCombinators.string),
              encryptedPassword: field.required("encryptedPassword", Json_Decode$JsonCombinators.string),
              createdAt: field.required("createdAt", Json_Decode$JsonCombinators.string),
              updatedAt: field.required("updatedAt", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

var Edit = {
  fromJs: fromJs,
  decoder: decoder,
  fromJson: fromJson
};

function fromJs$1(js) {
  return {
          id: js.id,
          name: js.name,
          active: js.active,
          agencyAgentsCount: js.agencyAgentsCount
        };
}

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              agencyAgentsCount: field.required("agencyAgentsCount", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

var Agency = {
  fromJs: fromJs$1,
  decoder: decoder$1,
  fromJson: fromJson$1
};

function fromJs$2(js) {
  return {
          tsdId: js.tsdId,
          agencies: Belt_Array.map(js.agencies, fromJs$1),
          totalAgencies: js.totalAgencies,
          totalPages: js.totalPages,
          currentPage: js.currentPage,
          perPage: js.perPage
        };
}

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              tsdId: field.required("tsdId", ID.decoder),
              agencies: field.required("agencies", Json_Decode$JsonCombinators.array(decoder$1)),
              totalAgencies: field.required("totalAgencies", Json_Decode$JsonCombinators.$$int),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              perPage: field.required("perPage", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$2(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$2));
}

var Agencies = {
  fromJs: fromJs$2,
  decoder: decoder$2,
  fromJson: fromJson$2
};

var Show = {
  fromJs: fromJs,
  decoder: decoder,
  fromJson: fromJson,
  Agency: Agency,
  Agencies: Agencies
};

function fromJs$3(js) {
  return {
          id: js.id,
          name: js.name,
          active: js.active,
          contractStart: js.contractStart,
          agenciesCount: js.agenciesCount,
          createdAt: js.createdAt
        };
}

var decoder$3 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              contractStart: field.required("contractStart", Json_Decode$JsonCombinators.string),
              agenciesCount: field.required("agenciesCount", Json_Decode$JsonCombinators.$$int),
              createdAt: field.required("createdAt", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$3(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$3));
}

var Dashboard = {
  fromJs: fromJs$3,
  decoder: decoder$3,
  fromJson: fromJson$3
};

var Pdf$1;

export {
  fromJs ,
  decoder ,
  fromJson ,
  Edit ,
  Show ,
  Dashboard ,
  Pdf$1 as Pdf,
}
/* decoder Not a pure module */
