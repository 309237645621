// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconSearch(props) {
  var color = props.color;
  var __title = props.title;
  var title = __title !== undefined ? __title : "Search";
  return JsxRuntime.jsxs(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: [
                JsxRuntime.jsx("path", {
                      d: "M7,10.3333333 C8.84094917,10.3333333 10.3333333,8.84094917 10.3333333,7 C10.3333333,5.15905083 8.84094917,3.66666667 7,3.66666667 C5.15905083,3.66666667 3.66666667,5.15905083 3.66666667,7 C3.66666667,8.84094917 5.15905083,10.3333333 7,10.3333333 Z M7,12 C4.23857625,12 2,9.76142375 2,7 C2,4.23857625 4.23857625,2 7,2 C9.76142375,2 12,4.23857625 12,7 C12,9.76142375 9.76142375,12 7,12 Z",
                      fill: Icon.mapColor(color)
                    }),
                JsxRuntime.jsx("path", {
                      d: "M9.4,10.4 L12.6,13.6",
                      stroke: Icon.mapColor(color),
                      strokeLinecap: "round",
                      strokeWidth: "2"
                    })
              ]
            });
}

var make = IconSearch;

export {
  make ,
}
/* Icon Not a pure module */
