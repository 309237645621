// Generated by ReScript, PLEASE EDIT WITH CARE

import * as TableScss from "./Table.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = TableScss;

function TableRow(props) {
  return JsxRuntime.jsx("tr", {
              children: props.children,
              className: props.className
            });
}

var make = TableRow;

export {
  css ,
  make ,
}
/* css Not a pure module */
