// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../styleguide/components/Link/A.res.js";
import * as Routes_Tsd from "../../../../../routes/common/Routes_Tsd.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardNavbarCss from "../../../../../styleguide/dashboard/components/navbar/DashboardNavbarCss.res.js";

function DashboardTsdNavbar(props) {
  var tsdName = props.tsdName;
  var tsdId = props.tsdId;
  var __currentLink = props.currentLink;
  var currentLink = __currentLink !== undefined ? __currentLink : "Index";
  var linkCss = function (link) {
    if (link === currentLink) {
      return DashboardNavbarCss.underlinedLinkContainer;
    } else {
      return DashboardNavbarCss.linkContainer;
    }
  };
  var tmp;
  tmp = currentLink === "Index" ? DashboardNavbarCss.underlinedLinkItem : DashboardNavbarCss.normallinkItem;
  var tmp$1;
  tmp$1 = currentLink === "New" ? DashboardNavbarCss.underlinedLinkItem : DashboardNavbarCss.normallinkItem;
  var tmp$2;
  var exit = 0;
  var id;
  if (tsdId !== undefined) {
    var id$1 = Caml_option.valFromOption(tsdId);
    if (tsdName !== undefined) {
      switch (currentLink) {
        case "Edit" :
            id = id$1;
            exit = 1;
            break;
        case "Index" :
        case "New" :
            tmp$2 = null;
            break;
        case "Show" :
            tmp$2 = JsxRuntime.jsx("div", {
                  children: JsxRuntime.jsx(A.make, {
                        href: Routes_Tsd.Dashboard.show(id$1),
                        className: DashboardNavbarCss.underlinedLinkItem,
                        children: tsdName
                      }),
                  className: linkCss("Show")
                });
            break;
        
      }
    } else if (currentLink === "Edit") {
      id = id$1;
      exit = 1;
    } else {
      tmp$2 = null;
    }
  } else {
    tmp$2 = null;
  }
  if (exit === 1) {
    tmp$2 = JsxRuntime.jsx("div", {
          children: JsxRuntime.jsx(A.make, {
                href: Routes_Tsd.Dashboard.edit(id),
                className: DashboardNavbarCss.underlinedLinkItem,
                children: "Edit TSD"
              }),
          className: linkCss("Edit")
        });
  }
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(A.make, {
                                  href: Routes_Tsd.Dashboard.index,
                                  className: tmp,
                                  children: "TSDs"
                                }),
                            className: linkCss("Index")
                          }),
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(A.make, {
                                  href: Routes_Tsd.Dashboard.$$new,
                                  className: tmp$1,
                                  children: "New TSD"
                                }),
                            className: linkCss("New")
                          }),
                      tmp$2
                    ],
                    className: DashboardNavbarCss.barContainer
                  }),
              className: DashboardNavbarCss.barWrapper
            });
}

var Css;

var make = DashboardTsdNavbar;

export {
  Css ,
  make ,
}
/* A Not a pure module */
