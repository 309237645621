// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Tsd from "../../../models/Tsd.res.js";
import * as Rest from "../../Rest.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function toString(x) {
  if (x === "Desc") {
    return "desc";
  } else {
    return "asc";
  }
}

var SortDirection = {
  toString: toString
};

var SortBy = {};

var Req = {
  SortDirection: SortDirection,
  SortBy: SortBy
};

function exec(tsdId, pageNum, sortBy, query) {
  var page = "page=" + String(pageNum);
  var query$1 = Belt_Option.getWithDefault(Belt_Option.map(query, (function (x) {
              return "&query=" + encodeURIComponent(x);
            })), "");
  var variant = sortBy.NAME;
  var sortBy$1 = variant === "AgentsCount" ? "&sort_by=agencies.agency_agents_count&sort_direction=" + toString(sortBy.VAL) : (
      variant === "Name" ? "&sort_by=agencies.name&sort_direction=" + toString(sortBy.VAL) : "&sort_by=agencies.active&sort_direction=" + toString(sortBy.VAL)
    );
  return Rest.$$fetch("/dashboard/tsds/" + ID.toString(tsdId) + "/agencies?" + (page + (query$1 + sortBy$1)), "Get", {
              NAME: "Json",
              VAL: Tsd.Show.Agencies.decoder
            }, undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* ID Not a pure module */
