// Generated by ReScript, PLEASE EDIT WITH CARE

import * as TableScss from "./Table.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = TableScss;

function TableHeader(props) {
  return JsxRuntime.jsx("thead", {
              children: props.children
            });
}

var make = TableHeader;

export {
  css ,
  make ,
}
/* css Not a pure module */
