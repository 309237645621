// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Caml from "rescript/lib/es6/caml.js";
import * as Char from "../../../libs/Char.res.js";
import * as Control from "../Control/Control.res.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as PaginationScss from "./Pagination.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = PaginationScss;

function range(start, end_) {
  if (start > end_) {
    return /* [] */0;
  } else if (start === end_) {
    return {
            hd: {
              TAG: "Page",
              _0: start
            },
            tl: /* [] */0
          };
  } else {
    return {
            hd: {
              TAG: "Page",
              _0: start
            },
            tl: range(start + 1 | 0, end_)
          };
  }
}

function generatePageNumbers(currentPage, totalPages) {
  var startPage = Caml.int_max(2, currentPage - 1 | 0);
  var endPage = Caml.int_min(totalPages - 1 | 0, currentPage + 1 | 0);
  var pages = range(startPage, endPage);
  var hasLeftSpill = startPage > 2;
  var hasRightSpill = (totalPages - endPage | 0) > 1;
  if (hasLeftSpill) {
    if (hasRightSpill) {
      return Belt_List.concatMany([
                  {
                    hd: "LeftArrow",
                    tl: {
                      hd: {
                        TAG: "Page",
                        _0: 1
                      },
                      tl: {
                        hd: "Ellipsis",
                        tl: /* [] */0
                      }
                    }
                  },
                  pages,
                  {
                    hd: "Ellipsis",
                    tl: {
                      hd: {
                        TAG: "Page",
                        _0: totalPages
                      },
                      tl: {
                        hd: "RightArrow",
                        tl: /* [] */0
                      }
                    }
                  }
                ]);
    } else {
      return Belt_List.concat({
                  hd: "LeftArrow",
                  tl: {
                    hd: {
                      TAG: "Page",
                      _0: 1
                    },
                    tl: {
                      hd: "Ellipsis",
                      tl: /* [] */0
                    }
                  }
                }, {
                  hd: {
                    TAG: "Page",
                    _0: totalPages
                  },
                  tl: {
                    hd: "RightArrow",
                    tl: /* [] */0
                  }
                });
    }
  } else if (hasRightSpill) {
    return Belt_List.concat({
                hd: "LeftArrow",
                tl: {
                  hd: {
                    TAG: "Page",
                    _0: 1
                  },
                  tl: /* [] */0
                }
              }, {
                hd: "Ellipsis",
                tl: {
                  hd: {
                    TAG: "Page",
                    _0: totalPages
                  },
                  tl: {
                    hd: "RightArrow",
                    tl: /* [] */0
                  }
                }
              });
  } else {
    return Belt_List.concatMany([
                {
                  hd: "LeftArrow",
                  tl: /* [] */0
                },
                range(1, totalPages),
                {
                  hd: "RightArrow",
                  tl: /* [] */0
                }
              ]);
  }
}

function isSpecialPage(pageNumber, specialPages) {
  if (specialPages !== undefined) {
    return Belt_Array.some(specialPages, (function (page) {
                  return Caml_obj.equal(page, pageNumber);
                }));
  } else {
    return false;
  }
}

function Pagination(props) {
  var __specialClassName = props.specialClassName;
  var specialPages = props.specialPages;
  var onPageClick = props.onPageClick;
  var totalPages = props.totalPages;
  var currentPage = props.currentPage;
  var specialClassName = __specialClassName !== undefined ? __specialClassName : "";
  var pageNumbers = generatePageNumbers(currentPage, totalPages);
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("nav", {
                    children: Belt_List.toArray(Belt_List.mapWithIndex(pageNumbers, (function (index, pageNumber) {
                                if (typeof pageNumber !== "object") {
                                  switch (pageNumber) {
                                    case "Ellipsis" :
                                        return JsxRuntime.jsx(Control.make, {
                                                    className: Cx.cx([
                                                          css.pageItem,
                                                          css.disabled,
                                                          css.symbol
                                                        ]),
                                                    children: "..."
                                                  }, "pagination" + String(index));
                                    case "LeftArrow" :
                                        return JsxRuntime.jsx(Control.make, {
                                                    className: Cx.cx([
                                                          css.pageItem,
                                                          currentPage === 1 ? css.disabled : "",
                                                          css.symbol
                                                        ]),
                                                    onClick: (function (param) {
                                                        onPageClick(currentPage - 1 | 0);
                                                      }),
                                                    children: JsxRuntime.jsx(Char.LeftArrow.make, {})
                                                  }, "pagination" + String(index));
                                    case "RightArrow" :
                                        return JsxRuntime.jsx(Control.make, {
                                                    className: Cx.cx([
                                                          css.pageItem,
                                                          currentPage === totalPages ? css.disabled : "",
                                                          css.symbol
                                                        ]),
                                                    onClick: (function (param) {
                                                        onPageClick(currentPage + 1 | 0);
                                                      }),
                                                    children: JsxRuntime.jsx(Char.RightArrow.make, {})
                                                  }, "pagination" + String(index));
                                    
                                  }
                                } else {
                                  var number = pageNumber._0;
                                  var tmp;
                                  tmp = typeof pageNumber !== "object" || pageNumber._0 !== currentPage ? "" : css.active;
                                  return JsxRuntime.jsx(Control.make, {
                                              className: Cx.cx([
                                                    css.pageItem,
                                                    tmp,
                                                    isSpecialPage(number, specialPages) ? specialClassName : ""
                                                  ]),
                                              onClick: (function (param) {
                                                  onPageClick(number);
                                                }),
                                              children: String(number)
                                            }, "pagination" + String(index));
                                }
                              })))
                  }),
              className: props.className
            });
}

var make = Pagination;

export {
  css ,
  range ,
  generatePageNumbers ,
  isSpecialPage ,
  make ,
}
/* css Not a pure module */
