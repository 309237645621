// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconMenu(props) {
  var color = props.color;
  var __title = props.title;
  var title = __title !== undefined ? __title : "Menu";
  return JsxRuntime.jsxs(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: [
                JsxRuntime.jsx("path", {
                      d: "M1.77778 9.55556C2.75962 9.55556 3.55556 8.75962 3.55556 7.77778C3.55556 6.79594 2.75962 6 1.77778 6C0.795938 6 0 6.79594 0 7.77778C0 8.75962 0.795938 9.55556 1.77778 9.55556Z",
                      fill: Icon.mapColor(color)
                    }),
                JsxRuntime.jsx("path", {
                      d: "M8.00001 9.55556C8.98185 9.55556 9.77778 8.75962 9.77778 7.77778C9.77778 6.79594 8.98185 6 8.00001 6C7.01817 6 6.22223 6.79594 6.22223 7.77778C6.22223 8.75962 7.01817 9.55556 8.00001 9.55556Z",
                      fill: Icon.mapColor(color)
                    }),
                JsxRuntime.jsx("path", {
                      d: "M14.2222 9.55556C15.2041 9.55556 16 8.75962 16 7.77778C16 6.79594 15.2041 6 14.2222 6C13.2404 6 12.4444 6.79594 12.4444 7.77778C12.4444 8.75962 13.2404 9.55556 14.2222 9.55556Z",
                      fill: Icon.mapColor(color)
                    })
              ]
            });
}

var make = IconMenu;

export {
  make ,
}
/* Icon Not a pure module */
